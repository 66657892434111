/* eslint-disable react/prop-types */
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useOnClickOutside from '../../../hooks/useClickOutside';
import useMenu from '../../../hooks/useMenu';

const FlexMenuContext = createContext();

const Trigger = ({ as: Component = 'button', children, ...props }) => {
  const { toggleMenu } = useContext(FlexMenuContext);
  return (
    <Component onClick={toggleMenu} {...props}>
      {children}
    </Component>
  );
};

const OptionList = ({ as: Component = 'ul', position, ...props }) => {
  const { isOpen } = useContext(FlexMenuContext);
  const [width, setWidth] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      setWidth(listRef.current?.clientWidth);
    }
  }, [isOpen]);

  return (
    <div
      ref={listRef}
      style={{
        position: 'absolute',
        backgroundColor: '#F6F6F7',
        borderRadius: 5,
        left: position === 'left' ? ` -${width - 42 || 0}px` : 0,
        zIndex: 999,
      }}
    >
      {isOpen ? <Component {...props} /> : null}
    </div>
  );
};

const FlexMenu = ({ children, disableClickOutside = false }) => {
  const {
    isOpen, toggleMenu, closeMenu, menuRef,
  } = useMenu();
  useOnClickOutside(menuRef, () => (!disableClickOutside && isOpen ? closeMenu() : null));
  return (
    <FlexMenuContext.Provider value={{
      isOpen, toggleMenu, closeMenu, menuRef,
    }}
    >
      <div ref={menuRef} style={{ position: 'relative' }}>
        {children}
      </div>
    </FlexMenuContext.Provider>
  );
};

FlexMenu.Trigger = Trigger;
FlexMenu.OptionList = OptionList;

// Add a hook to expose the menu functionality
export const useFlexMenu = () => useContext(FlexMenuContext);

export default FlexMenu;
