/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { Book } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LinearProgress } from '@material-ui/core';
import ProductAPI from '../../../api/ProductAPI';
import { changeMeiliIndex, finishRegenerateIndex, regenerateIndex } from '../../../redux/actions/priceBookActions';

const ActiveIndex = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.authUser);
  const pricebook = useSelector(state => state.pricebook);
  const [activePriceBook, setActivePriceBook] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [retries, setRetries] = useState(0);
  const { user: { pricebooks_ref, PriceBooks } } = authUser;
  const priceBookList = pricebooks_ref || PriceBooks || [];
  // const { user: { PriceBooks } } = authUser;
  const { meiliIndex } = pricebook;
  const productAPI = new ProductAPI();

  // Emit custom event to clear search
  const clear = () => {
    const event = new Event('clearSearch');
    document.dispatchEvent(event);
  };

  const checkIfMeiliIndexExists = async () => {
    const indexId = meiliIndex.split('_')[1] || 0;
    setIsLoading(true);
    setIsChecking(true);
    const response = await productAPI.checkMeiliIndex(indexId);
    setIsLoading(false);
    setIsChecking(false);
    const { success, result } = response;

    if (success) {
      if (retries > 0) {
        toast.success('Indice regenerado correctamente, cargando productos...');
        // dispatch(changeMeiliIndex(`${authUser.user.comercia_cuenta}_${indexId}`));
        setRetries(0);
        dispatch(finishRegenerateIndex());
      }
      return;
    }

    if (!success && result && (result.status_code || result.code)) {
      if (result.status_code === 1200 || result.code === 1200) {
        setIsLoading(true);
        dispatch(regenerateIndex());
        setRetries(retries + 1);
        toast.warn('El indice se esta regenerando, porfavor espere unos minutos');
      } else {
        toast.error(result.message ? result.message : 'Error al intentar obtener el índice de MeiliSearch');
        setIsLoading(false);
        setRetries(0);
      }
    } else {
      console.log('Indice activo y funcionando correctamente');
      // toast.success('Indice activo y funcionando correctamente');
    }
  };

  useEffect(() => {
    if (meiliIndex) {
      const indexId = meiliIndex.split('_')[1];
      const foundPricebook = priceBookList.find(p => p.crmid === indexId);
      setActivePriceBook(foundPricebook);
    }
    checkIfMeiliIndexExists();
  }, [meiliIndex]);

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      if (retries < 4 && retries > 0) {
        setRetries(retries + 1);
        checkIfMeiliIndexExists();
      } else {
        if (retries > 0 && isLoading) {
          toast.error('El indice no se ha regenerado correctamente, porfavor intente de nuevo más tarde');
        }
        setIsLoading(false);
      }
    }, 5000);

    return () => {
      clearTimeout(idTimeout);
    };
  }, [retries]);

  return (
    <div>
      <div style={{
        padding: '5px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px',
      }}
      >
        <Book style={{ color: '#747474' }} />
        <span style={{ color: '#747474' }}>{activePriceBook ? activePriceBook.entity_label : 'Predeterminada'}</span>
      </div>
      {
        (isLoading || isChecking) && <LinearProgress />
      }
    </div>
  );
};

export default ActiveIndex;
