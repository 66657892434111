import { useRef, useState } from 'react';

const useMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  function toggleMenu() {
    setIsOpen(!isOpen);
  }
  const closeMenu = () => {
    setIsOpen(false);
  };


  return {
    isOpen,
    toggleMenu,
    closeMenu,
    menuRef,
  };
};

export default useMenu;
